import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useIsAuthorised } from 'shared/auth/auth-utils';
import PrivateComponent from 'shared/auth/privateComponent';
import { IDashboard } from 'shared/model/dashboard.model';
import { deleteDashboard, fetchDashboards } from 'shared/reducers/dashboardSlice';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import CreateOrEditDashBoardDialog from './dialogs/createOrEditDashboardDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

const Dashboards = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const canCreateDashboard = useIsAuthorised('Dashboard', ['CREATE']);
  const dashboards = useSelector(({ dashboard }: IRootState) => dashboard.dashboards);
  const loading = useSelector(({ dashboard }: IRootState) => dashboard.loading);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const [selectedRows, setSelectedRows] = React.useState<IDashboard[]>([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const isSelectable = useIsAuthorised('Dashboard', ['DELETE', 'UPDATE']);
  const columns: IDataTableColumn<IDashboard>[] = useMemo(
    () => [
      {
        selector: 'dashboard_id',
        name: 'id',
        omit: true
      },
      {
        selector: 'name',
        name: t('name'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'group_ids',
        name: t('group', { count: 2 }),
        grow: 3,
        hide: 'sm',
        format: (row: IDashboard) => (
          <Box display="flex" flexWrap="wrap">
            {row.group_ids?.map(groupid => {
              const groupDef = groups.find(aGroup => aGroup.group_id === groupid);
              if (groupDef) {
                return (
                  <Chip
                    key={groupDef.group_id}
                    label={groupDef.group_name}
                    variant="outlined"
                    className={classes.chip}
                    size="small"
                  />
                );
              }
              return null;
            })}
          </Box>
        )
      }
    ],
    [classes.chip, groups, t]
  );

  const actions = (
    <PrivateComponent resource="Dashboard" operation={['CREATE']}>
      <CreateOrEditDashBoardDialog />
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteDashboard(selectedRows[i], false));
      }
      onSuccess();
      dispatch(fetchDashboards());
    };

    const onSuccess = () => {
      setToggleCleared(!toggleCleared);
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent resource="Dashboard" operation={['UPDATE']}>
            <CreateOrEditDashBoardDialog dashboard={selectedRows[0]} onSuccess={onSuccess} />
          </PrivateComponent>
        )}
        <PrivateComponent resource="Dashboard" operation={['DELETE']}>
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  useEffect(() => {
    dispatch(fetchDashboards());
  }, [dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleRowClicked = (row: IDashboard) => {
    history.push(`/dashboard/${row.dashboard_id}`);
  };

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return (
      <TitleWithSearchField
        title={t('dashboard', { count: 100 })}
        placeholder={t('name')}
        onChange={onChange}
        autoFocus
      />
    );
  }, [resetPaginationToggle, t]);

  if (!canCreateDashboard && dashboards.length === 1) {
    return <Redirect to={`/dashboard/${dashboards[0].dashboard_id}`} />;
  }

  const data =
    filterText.length > 0
      ? dashboards.filter(item =>
          item.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        )
      : dashboards;

  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        selectableRows={isSelectable}
        defaultSortField="name"
        actions={actions}
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        onRowClicked={handleRowClicked}
        progressPending={loading}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Dashboards;
