import React, { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip as MaterialChip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as CalibrateIcon } from '../../../shared/icons/CalibrateIcon.svg';
import CalibrateDialog from 'modules/devices/actions/calibrateDialog';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Phone from '@material-ui/icons/Phone';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import { openChat } from '../../../shared/utils/chat.utils';
import { AcUnit, WifiOff } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { IDevice } from 'shared/model/device.model';
import PrivateComponent from 'shared/auth/privateComponent';

const DeliveryChip = ({ device }: { device: IDevice }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={t('missing_delivery')}
      color={'#2E48A7'}
      icon={<LocalShippingIcon />}
      device={device}
      dialog={DeliveryDialog}
    />
  );
};

const LevelChip = ({ device }: { device: IDevice }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={t('missing_level')}
      color={'#2E48A7'}
      icon={<CalibrateIcon />}
      device={device}
      dialog={CalibrateDialog}
    />
  );
};

const SupportChip = () => {
  const { t } = useTranslation();
  return (
    <Chip
      label={t('contact_support')}
      color={'#E83E6D'}
      icon={<Phone />}
      contrastColor={'#FCDCE5'}
      onClick={openChat}
    />
  );
};

const ProblemCause = ({ problem_cause }: { problem_cause: string }) => {
  const { t } = useTranslation();
  let icon = <WifiOff fontSize="inherit" />;
  if (problem_cause === 'trapped_in_ice') icon = <AcUnit fontSize="inherit" />;
  return (
    <Typography
      color="error"
      style={{ gap: 5, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      variant="body2"
    >
      {icon}
      {t(`devices_status.${problem_cause}.label`)}
    </Typography>
  );
};

const calibInfos: Record<string, React.FC<{ device: IDevice }>> = {
  missing_level: LevelChip,
  missing_level_delivery: ({ device }: { device: IDevice }) => (
    <div
      style={{ display: 'flex', flexDirection: 'column', paddingTop: 3, paddingBottom: 3, gap: 5 }}
    >
      <DeliveryChip device={device} />
      <LevelChip device={device} />
    </div>
  ),
  missing_delivery: DeliveryChip
};

const Chip = (
  props: ComponentProps<typeof MaterialChip> & {
    dialog: React.FC<any>;
    device: IDevice;
    color: string;
    contrastColor?: string;
  }
) => {
  const [open, setOpen] = useState(false);
  const CustomDialog = props.dialog;
  const { color, contrastColor, ...other } = props;
  const StyledChip = withStyles({
    outlined: {
      color: color,
      border: `1px solid ${color}`,
      '&:hover': {
        backgroundColor: `${contrastColor ?? '#F3F5FC'} !important`
      }
    },
    icon: {
      color: props.color
    }
  })(MaterialChip);
  return (
    <>
      {open && (
        <CustomDialog device={props.device} open={open} handleClose={() => setOpen(false)} />
      )}
      <StyledChip
        onClick={props.onClick ?? (() => setOpen(true))}
        {...other}
        variant="outlined"
        size="small"
        fullWidth
      />
    </>
  );
};

/**
 *
 */
const MissingInfoField = ({ device }: { device: IDevice }) => {
  const { info_to_calibrate, problem_cause } = device.metadata ?? {};
  const CalibInfoComponent = calibInfos[info_to_calibrate as string];
  const { t } = useTranslation();

  if (
    (device.status === 'pending' || device.status === 'ok' || device.status === 'calibrating') &&
    info_to_calibrate &&
    CalibInfoComponent
  ) {
    return (
      <PrivateComponent
        resource="Calibrations"
        operation={['CREATE']}
        otherwise={<>t{`devices_status.${device.status}.label`}</>}
      >
        <CalibInfoComponent device={device} />
      </PrivateComponent>
    );
  } else if (
    device.status === 'problem' &&
    ['out_of_data', 'trapped_in_ice'].includes(problem_cause)
  ) {
    return <ProblemCause problem_cause={problem_cause} />;
  } else if (['problem', 'calibration_problem', 'error', 'level_problem'].includes(device.status)) {
    return <SupportChip />;
  } else {
    return t(`devices_status.${device.status}.label`);
  }

  //   if (
  //     (device.status === 'pending' || device.status === 'ok' || device.status === 'calibrating') &&
  //     info_to_calibrate &&
  //     Object.keys(infos).includes(info_to_calibrate)
  //   )
  //     return (

  //         {infos[info_to_calibrate](device, t)}
  //       </PrivateComponent>
  //     );
  //   if (device.status === 'problem' && ['out_of_data', 'trapped_in_ice'].includes(problem_cause))
  //     return <ProblemCause problem_cause={problem_cause} t={t} />;
  //   if (['problem', 'calibration_problem', 'error', 'level_problem'].includes(device.status))
  //     return <SupportChip t={t} />;
  //   return t(`devices_status.${device.status}.label`);
};

export default MissingInfoField;
